import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { properties } from "../../content/Properties"
import InnerPropertyPage from "../../components/molecules/InnerPropertyPage"

const slug = `21-mantis-gr-karnup-western-australia`

const post = properties.find(post => post.slug === slug) // Find the blog post by slug

const MantisGrKarnupWesternAustralia = () => (
  <Layout>
    <Seo
      title="High Physical Support SDA Property in Karnup – Modern Living"
      description="Discover a High Physical Support SDA property in Karnup, offering fully accessible living with modern amenities designed for maximum comfort and independence."
    />

    <InnerPropertyPage post={post} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default MantisGrKarnupWesternAustralia
